import classes from "./Input.module.css";

const SelectLabel = (props: any) => {
  return (
    <div className={classes.input}>
      <div className={classes.label_div}>
        <label htmlFor={props.label}>{props.label}</label>
      </div>
    </div>
  );
};

export default SelectLabel;
