import StartingContent from "../components/Index/StartingContent";
import Layout from "../components/Layout/Layout";

const HomePage = () => {
  return (
    <Layout>
      <StartingContent />
    </Layout>
  );
};
export default HomePage;
