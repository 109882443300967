import classes from "./DashboardNavigation.module.css";
import ProfileIcon from "../../assets/account.svg";
import ProfileIconFilled from "../../assets/account_filled.svg";
import MemberIcon from "../../assets/member.svg";
import MemberIconFilled from "../../assets/member_filled.svg";
import MessageIcon from "../../assets/message.svg";
import MessageIconFilled from "../../assets/message_filled.svg";
import AppIcon from "../../assets/app-icon.png";
import DashboardIcon from "../../assets/dashboard.svg";
import DashboardIconFilled from "../../assets/dashboard_filled.svg";
import MemberAddIcon from "../../assets/member_add.svg";
import MemberAddIconFilled from "../../assets/add_member_filled.svg";
import LogoutIcon from "../../assets/logout.svg";
import LogoutIconFilled from "../../assets/logout_filled.svg";
import { useState } from "react";
import Members from "../Members/Members";
import Dashboard from "../Dashboard/Dashboard";
import AddMember from "../Members/AddMember";
import Message from "../Message/Message";
import Profile from "../Profile/Profile";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authActions } from "../../store/auth-slice";
import { Routes } from "../../constants/Routes";

const DashboardNavigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDashboard, setDashboard] = useState(true);
  const [isMember, setMember] = useState(false);
  const [isAddMeber, setAddMember] = useState(false);
  const [isMessage, setMessage] = useState(false);
  const [isProfile, setProfile] = useState(false);
  const [isLogout, setLogout] = useState(false);

  const dashboardOnClick = () => {
    setDashboard(true);
    setMember(false);
    setAddMember(false);
    setMessage(false);
    setProfile(false);
    setLogout(false);
  };

  const memberOnClick = () => {
    setDashboard(false);
    setMember(true);
    setAddMember(false);
    setMessage(false);
    setProfile(false);
    setLogout(false);
  };

  const addMemberOnClick = () => {
    setDashboard(false);
    setMember(false);
    setAddMember(true);
    setMessage(false);
    setProfile(false);
    setLogout(false);
  };

  const messageOnClick = () => {
    setDashboard(false);
    setMember(false);
    setAddMember(false);
    setMessage(true);
    setProfile(false);
    setLogout(false);
  };

  const profileOnClick = () => {
    setDashboard(false);
    setMember(false);
    setAddMember(false);
    setMessage(false);
    setProfile(true);
    setLogout(false);
  };

  const logoutOnClick = () => {
    setDashboard(false);
    setMember(false);
    setAddMember(false);
    setMessage(false);
    setProfile(false);
    setLogout(true);
    dispatch(authActions.logout());
    history.push(Routes.INDEX);
  };

  return (
    <div className={classes.mainbox}>
      <div className={classes.leftbox}>
        <nav>
          <div className={classes.imageDiv} onClick={dashboardOnClick}>
            <img className={classes.image} src={AppIcon} alt="Dashboard"
            title="MyComunity"/>
          </div>
          <div className={classes.imageDiv} onClick={dashboardOnClick}>
            <img
              className={classes.image}
              src={isDashboard ? DashboardIconFilled : DashboardIcon}
              alt="Dashboard"
              title="Dashboard"
            />
          </div>
          <div className={classes.imageDiv} onClick={memberOnClick}>
            <img
              className={classes.image}
              src={isMember ? MemberIconFilled : MemberIcon}
              alt="Member"
              title="Member"
            />
          </div>
          <div className={classes.imageDiv} onClick={addMemberOnClick}>
            <img
              className={classes.image}
              src={isAddMeber ? MemberAddIconFilled : MemberAddIcon}
              alt="Add Member"
              title="Add Member"
            />
          </div>
          <div className={classes.imageDiv} onClick={messageOnClick}>
            <img
              className={classes.image}
              src={isMessage ? MessageIconFilled : MessageIcon}
              alt="Message"
              title="Message"
            />
          </div>
          <div className={classes.imageDiv} onClick={profileOnClick}>
            <img
              className={classes.image}
              src={isProfile ? ProfileIconFilled : ProfileIcon}
              alt="Profile"
              title="Profile"
            />
          </div>
          <div className={classes.imageDiv} onClick={logoutOnClick}>
            <img
              className={classes.image}
              src={isLogout ? LogoutIconFilled : LogoutIcon}
              alt="Logout"
              title="Logout"
            />
          </div>
        </nav>
      </div>
      <div className={classes.rightbox}>
        {isDashboard && <Dashboard />}
        {isMember && <Members />}
        {isAddMeber && <AddMember />}
        {isMessage && <Message />}
        {isProfile && <Profile />}
      </div>
    </div>
  );
};
export default DashboardNavigation;
