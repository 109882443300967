import AuthForm from "../components/Auth/AuthForm";
import Layout from "../components/Layout/Layout";

const Login = () => {
  return (
    <Layout>
      <AuthForm />
    </Layout>
  );
};
export default Login;
