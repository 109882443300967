import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { F_LOGIN } from "../../constants/ApiPath";
import { Routes } from "../../constants/Routes";
import useInput from "../../hooks/UserInput";
import { authActions } from "../../store/auth-slice";
import Button from "../UI/Button";
import ErrorText from "../UI/ErrorText";
import Input from "../UI/Input";
import LoadingSpinner from "../UI/LoadingSpinner";
import classes from "./AuthForm.module.css";
const AuthForm = () => {
  const initialState = { error: "", status: 0, message: "" };
  const [resposeError, setResposneError] = useState(initialState);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(
    (value: any) => value.trim() !== "" && value.trim().includes("@")
  );

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput((value: any) => value.trim() !== "" && value.trim().length >= 4);

  let isFormValid = false;
  if (emailIsValid && passwordIsValid) {
    isFormValid = true;
  }

  const formSubmitHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }
    setIsLoading(true);
    const requestData = {
      //username: emailValue,
      email: emailValue,
      password: passwordValue,
    };
    axios
      .post(F_LOGIN, requestData)
      .then((response) => {
        setIsLoading(false);
        //dispatch(authActions.login(response.data.access_token));
        dispatch(authActions.login(response.data.idToken));
        resetPassword();
        resetEmail();
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        //console.log(error.response.data);
        console.log(error.response.data.error);
        //setResposneError(error.response.data);
        setResposneError(error.response.data.error);
        setIsError(true);
      });
  };

  const emailClasses = emailHasError ? "form-control invalid" : "form-control";
  const passwordClasses = passwordHasError
    ? "form-control invalid"
    : "form-control";

  return (
    <div className={classes.login}>
      {isLoading && <LoadingSpinner />}
      <div className={classes.header_div}>
        <h1 className={classes.header}>Login to your account</h1>
      </div>
      <div>
        <form className={classes.form} onSubmit={formSubmitHandler}>
          <Input
            classes={emailClasses}
            label="Email"
            input={{
              value: emailValue,
              id: "email",
              type: "email",
              placeholder: "example@company.com",
            }}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            error={emailHasError ? "Email must not be empty." : ""}
          />

          <Input
            classes={passwordClasses}
            label="Password"
            input={{
              value: passwordValue,
              id: "password",
              type: "password",
              placeholder: "**********",
            }}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
            error={
              passwordHasError ? "Password must be minimum 4 charactors." : ""
            }
          />
          {isError && resposeError.error !== "" && (
            <ErrorText error={resposeError.error} center={true} />
          )}
          {isError && resposeError.message !== "" && (
            <ErrorText error={resposeError.message} center={true} />
          )}
          <Button>Login</Button>
        </form>
        <div className={classes.signup}>
          <label>Don't have an account yet?</label>
          <NavLink to={Routes.SIGN_UP}>Sign Up</NavLink>
        </div>
      </div>
    </div>
  );
};
export default AuthForm;
