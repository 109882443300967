import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { CONFIG, F_SIGN_UP, SIGN_UP } from "../../constants/ApiPath";
import {
  COUNTRY,
  getCountryObject,
  getOrgTypeObject,
  getStateObject,
  ORG_TYPE,
  STATE,
} from "../../constants/Constants";
import Button from "../UI/Button";
import ErrorText from "../UI/ErrorText";
import Input from "../UI/Input";
import LoadingSpinner from "../UI/LoadingSpinner";
import SelectLabel from "../UI/SelectLabel";
import classes from "./Profile.module.css";

const Profile = () => {
  const initialState = { error: "", status: 0, message: "" };
  const [isLoading, setIsLoading] = useState(false);
  const [orgName, setOrgName] = useState();
  const [orgNameHasError, setOrgNameHasError] = useState();
  const [name, setName] = useState();
  const [nameHasError, setNameHasError] = useState();
  const [mobileNumber, setMobileNumber] = useState(0);
  const [mobileHasError, setMobileHasError] = useState();
  const [addressOne, setAddressOne] = useState();
  const [addressOneHasError, setAddressOneHasError] = useState();
  const [addressTwo, setAddressTwo] = useState();
  const [addressTwoHasError, setAddressTwoHasError] = useState();
  const [country, setCountry] = useState(COUNTRY[0]);
  const [city, setCity] = useState();
  const [cityHasError, setCityHasError] = useState();
  const [type, setType] = useState(ORG_TYPE[0]);
  const [email, setEmail] = useState();
  const [emailHasError, setEmailHasError] = useState();
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [phoneHasError, setPhoneHasError] = useState();
  const [state, setState] = useState(STATE[0]);
  const [zipcode, setZipcode] = useState();
  const [zipcodeHasError, setZipcodeHasError] = useState();
  const [resposeError, setResposneError] = useState(initialState);
  const [logoApp, setLogoApp] = useState();
  const [logoOthers, setLogoOthers] = useState();
  const [ip, setIp] = useState();
  const [id, setId] = useState();
  const [isError, setIsError] = useState(false);

  const getOrganization = async () => {
    setIsLoading(true);
    await axios
      //.get(SIGN_UP, CONFIG)
      .get(F_SIGN_UP)
      .then((response) => {
        //const org = response.data[0];
        //setData(org);
        setFirebasedata(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const setFirebasedata = (response: any) => {
    let org: any;
    for (const key in response.data) {
      if (key === "-MiMxsVx97j_v_d3nDzj") {
        org = {
          id: "-MiMxsVx97j_v_d3nDzj",
          name: response.data[key].name,
          addressLineOne: response.data[key].addressLineOne,
          addressLineTwo: response.data[key].addressLineTwo,
          city: response.data[key].city,
          state: response.data[key].state,
          country: response.data[key].country,
          zipcode: response.data[key].zipcode,
          contactName: response.data[key].contactName,
          contactEmail: response.data[key].contactEmail,
          contactMobile: response.data[key].contactMobile,
          contactPhone: response.data[key].contactPhone,
          type: response.data[key].type,
          ip: response.data[key].ip,
          logoApp: response.data[key].logoApp,
        };
      }
    }
    setData(org);
  };

  useEffect(() => {
    getOrganization();
  }, []);

  const orgNameChangeHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    setOrgName(event.target.value);
  };

  const orgNameBlurHandler = (event: React.ChangeEvent<HTMLFormElement>) => {};

  const nameChangeHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    setName(event.target.value);
  };

  const nameBlurHandler = () => {};

  const mobileChangeHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    setMobileNumber(event.target.value);
  };

  const mobileBlurHandler = () => {};

  const addressOneChangeHandler = (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    setAddressOne(event.target.value);
  };

  const addressOneBlurHandler = () => {};

  const addressTwoChangeHandler = (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    setAddressTwo(event.target.value);
  };

  const addressTwoBlurHandler = () => {};

  const countryChangeHandler = (selectedCountry: any) => {
    setCountry(selectedCountry);
  };

  const cityChangeHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    setCity(event.target.value);
  };

  const cityBlurHandler = () => {};

  const typeChangeHandler = (selectedType: any) => {
    setType(selectedType);
  };

  const emailChangeHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    setEmail(event.target.value);
  };

  const emailBlurHandler = () => {};

  const phoneChangeHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    setPhoneNumber(event.target.value);
  };

  const phoneBlurHandler = () => {};

  const stateChangeHandler = (selectedState: any) => {
    setState(selectedState);
  };

  const zipcodeChangeHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    setZipcode(event.target.value);
  };

  const zipcodeBlurHandler = () => {};

  const onSubmitHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const requestData = {"-MiMxsVx97j_v_d3nDzj": {
        name: orgName,
        addressLineOne: addressOne,
        addressLineTwo: addressTwo,
        city: city,
        state: state.label,
        country: country.label,
        zipcode: zipcode,
        contactName: name,
        contactEmail: email,
        contactMobile: +mobileNumber,
        contactPhone: +phoneNumber,
        type: type.label,
        ip: ip,
        logoApp: logoApp
    }};
    axios
      //.put(SIGN_UP + `/${id}`, requestData, CONFIG)
      .put(F_SIGN_UP, requestData)
      .then((response) => {
        setIsLoading(false);
        //setData(response.data);
        setFirebasedata(response);
      })
      .catch((error) => {
        setIsLoading(false);
        setResposneError(error.response.data);
        setIsError(true);
      });
  };

  const setData = (org: any) => {
    setOrgName(org.name);
    setName(org.contactName);
    setMobileNumber(org.contactMobile);
    setAddressOne(org.addressLineOne);
    setAddressTwo(org.addressLineTwo);
    setCountry(getCountryObject(org.country));
    setCity(org.city);
    setType(getOrgTypeObject(org.type));
    setEmail(org.contactEmail);
    setPhoneNumber(org.contactPhone);
    setState(getStateObject(org.state));
    setZipcode(org.zipcode);
    setLogoApp(org.logoApp);
    setLogoOthers(org.logoOthers);
    setIp(org.ip);
    setId(org.id);
  };

  return (
    <Fragment>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <Fragment>
          <div className={classes.login}>
            <img src={logoApp} alt="Logo" className={classes.img} />
          </div>
          <div className={classes.container}>
            <form className={classes.form} onSubmit={onSubmitHandler}>
              <div className={classes.leftbox}>
                <Input
                  label="Organisation Name*"
                  input={{
                    value: orgName,
                    id: "orgName",
                    type: "text",
                    placeholder: "Name of the organisation",
                  }}
                  onChange={orgNameChangeHandler}
                  onBlur={orgNameBlurHandler}
                  error={
                    orgNameHasError
                      ? "Organization name must be minimum 4 charactors."
                      : ""
                  }
                />
                <Input
                  label="Contact Mobile*"
                  input={{
                    value: mobileNumber,
                    id: "mobile",
                    type: "number",
                    placeholder: "MobileNumber",
                  }}
                  onChange={mobileChangeHandler}
                  onBlur={mobileBlurHandler}
                  error={
                    mobileHasError
                      ? "Contact mobile number must have 10 digits."
                      : ""
                  }
                />
                <Input
                  label="Address Line One*"
                  input={{
                    value: addressOne,
                    id: "addressOne",
                    type: "text",
                    placeholder: "Address Line One",
                  }}
                  onChange={addressOneChangeHandler}
                  onBlur={addressOneBlurHandler}
                  error={
                    addressOneHasError
                      ? "Address must be minimum 10 charactors."
                      : ""
                  }
                />
                <SelectLabel label="State*" />
                <Select
                  placeholder={"State"}
                  options={STATE}
                  defaultValue={state}
                  onChange={stateChangeHandler}
                />
              </div>
              <div className={classes.center}>
                <Input
                  label="Contact Name*"
                  input={{
                    value: name,
                    id: "name",
                    type: "text",
                    placeholder: "Contact Name",
                  }}
                  onChange={nameChangeHandler}
                  onBlur={nameBlurHandler}
                  error={
                    nameHasError
                      ? "Contact name must be minimum 4 charactors."
                      : ""
                  }
                />
                <Input
                  label="Contact Phone*"
                  input={{
                    value: phoneNumber,
                    id: "phoneNumber",
                    type: "number",
                    placeholder: "Phone Number",
                  }}
                  onChange={phoneChangeHandler}
                  onBlur={phoneBlurHandler}
                  error={
                    phoneHasError ? "Phone number must have 6 digits." : ""
                  }
                />
                <Input
                  label="Address Line Two"
                  input={{
                    value: addressTwo,
                    id: "addressTwo",
                    type: "text",
                    placeholder: "Address Line Two",
                  }}
                  onChange={addressTwoChangeHandler}
                  onBlur={addressTwoBlurHandler}
                  error={addressTwoHasError ? "" : ""}
                />
                <Input
                  label="City*"
                  input={{
                    value: city,
                    id: "city",
                    type: "text",
                    placeholder: "City",
                  }}
                  onChange={cityChangeHandler}
                  onBlur={cityBlurHandler}
                  error={
                    cityHasError
                      ? "City name must be minimum 4 charactors."
                      : ""
                  }
                />
              </div>
              <div className={classes.rightbox}>
                <Input
                  label="Contact Email*"
                  input={{
                    value: email,
                    id: "email",
                    type: "email",
                    placeholder: "example@company.com",
                  }}
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                  error={emailHasError ? "Provide a valid email address." : ""}
                />
                <SelectLabel label="Type*" />
                <Select
                  placeholder={"Type"}
                  options={ORG_TYPE}
                  defaultValue={type}
                  onChange={typeChangeHandler}
                />
                <SelectLabel label="Country*" />
                <Select
                  placeholder={"Country"}
                  options={COUNTRY}
                  defaultValue={country}
                  onChange={countryChangeHandler}
                />
                <Input
                  label="Zipcode*"
                  input={{
                    value: zipcode,
                    id: "zipcode",
                    type: "text",
                    placeholder: "Zipcode",
                  }}
                  onChange={zipcodeChangeHandler}
                  onBlur={zipcodeBlurHandler}
                  error={
                    zipcodeHasError
                      ? "Zipcode must be minimum 6 charactors."
                      : ""
                  }
                />
              </div>
              <div className={classes.button_div}>
                <Button>UPDATE</Button>
                {isError && resposeError.error !== "" && (
                  <ErrorText error={resposeError.error} />
                )}
                {isError && resposeError.message !== "" && (
                  <ErrorText error={resposeError.message} />
                )}
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
export default Profile;
