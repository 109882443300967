import axios from "axios";
import { Fragment, useState } from "react";
import { CONFIG, F_MESSAGE, MESSAGE } from "../../constants/ApiPath";
import Button from "../UI/Button";
import ErrorText from "../UI/ErrorText";
import LoadingSpinner from "../UI/LoadingSpinner";
import TextArea from "../UI/TextArea";
import classes from "./Message.module.css";

const Message = () => {
  const initialState = { error: "", status: 0, message: "" };
  const [isLoading, setIsLoading] = useState(false);
  const [resposeError, setResposneError] = useState(initialState);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const messageChangeHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    setMessage(event.target.value);
    if (message.length > 0) {
      setIsError(false);
    }
  };
  const onSubmitHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (message.length > 0) {
      setIsLoading(true);
      const requestData = {
        from: 1,
        to: 1,
        message: message,
        origin: "OTM",
      };
      axios
        //.post(MESSAGE, requestData, CONFIG)
        .post(F_MESSAGE, requestData)
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          setMessage("");
        })
        .catch((error) => {
          setIsLoading(false);
          setResposneError(error.response.data);
          setIsError(true);
        });
    } else {
      initialState.message = "Please enter the message";
      setIsError(true);
    }
  };
  return (
    <Fragment>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div className={classes.container}>
          <div className={classes.message}>
            <h1>Message</h1>
          </div>
          <form className={classes.form} onSubmit={onSubmitHandler}>
            <TextArea
              id="message"
              rows={8}
              name="message"
              value={message}
              cols={100}
              onChange={messageChangeHandler}
            />
            <div className={classes.button_div}>
              {isError && resposeError.error !== "" && (
                <ErrorText error={resposeError.error} />
              )}
              {isError && resposeError.message !== "" && (
                <ErrorText error={resposeError.message} />
              )}
              <Button>SEND</Button>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};
export default Message;
