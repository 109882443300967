import classes from './StartingContent.module.css';

const StartingContent = () => {
  return <section className={classes.starting}>
  <h1>Welcome to MyComunity!</h1>
</section>

};

export default StartingContent;
