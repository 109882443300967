export const Constants = {
  LOGGED_IN: "loggedIn",
  AUTH_TOKEN: "authToken",
  LOGIN: "1",
  LOGOUT: "0",
};

/*export const ORG_TYPE = [
  "Commercial",
  "Non Government Organization",
  "Trust",
  "Charity",
  "Club",
  "Community",
  "Others",
];*/
export const ORG_TYPE = [
  {
    id: "111",
    value: "Commercial",
    label: "Commercial",
  },
  {
    id: "112",
    value: "Non Government Organization",
    label: "Non Government Organization",
  },
  {
    id: "113",
    value: "Trust",
    label: "Trust",
  },
  {
    id: "114",
    value: "Charity",
    label: "Charity",
  },
  {
    id: "115",
    value: "Club",
    label: "Club",
  },
  {
    id: "116",
    value: "Community",
    label: "Community",
  },
  {
    id: "117",
    value: "Others",
    label: "Others",
  },
];

export const getOrgTypeObject = (type: string) => {
  let typeJson: any;
  ORG_TYPE.map((typeObj: any) => {
    if (typeObj.label === type) {
      typeJson = typeObj;
    }
  });
  return typeJson;
};

export const COUNTRY = [
  {
    id: "0001",
    value: "india",
    label: "India",
  },
];

export const getCountryObject = (country: string) => {
  let countryJson: any;
  COUNTRY.map((countryObj: any) => {
    if (countryObj.label === country) {
      countryJson = countryObj;
    }
  });
  return countryJson;
};

/*export const STATE = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];*/

export const STATE = [
  {
    id: "1",
    value: "Andhra Pradesh",
    label: "Andhra Pradesh",
  },
  {
    id: "2",
    value: "Arunachal Pradesh",
    label: "Arunachal Pradesh",
  },
  {
    id: "3",
    value: "Assam",
    label: "Assam",
  },
  {
    id: "4",
    value: "Bihar",
    label: "Bihar",
  },
  {
    id: "5",
    value: "Chhattisgarh",
    label: "Chhattisgarh",
  },
  {
    id: "6",
    value: "Goa",
    label: "Goa",
  },
  {
    id: "7",
    value: "Gujarat",
    label: "Gujarat",
  },
  {
    id: "8",
    value: "Haryana",
    label: "Haryana",
  },
  {
    id: "9",
    value: "Himachal Pradesh",
    label: "Himachal Pradesh",
  },
  {
    id: "10",
    value: "Jharkhand",
    label: "Jharkhand",
  },
  {
    id: "11",
    value: "Karnataka",
    label: "Karnataka",
  },
  {
    id: "12",
    value: "Kerala",
    label: "Kerala",
  },
  {
    id: "13",
    value: "Madhya Pradesh",
    label: "Madhya Pradesh",
  },
  {
    id: "14",
    value: "Maharashtra",
    label: "Maharashtra",
  },
  {
    id: "15",
    value: "Manipur",
    label: "Manipur",
  },
  {
    id: "16",
    value: "Meghalaya",
    label: "Meghalaya",
  },
  {
    id: "17",
    value: "Mizoram",
    label: "Mizoram",
  },
  {
    id: "18",
    value: "Nagaland",
    label: "Nagaland",
  },
  {
    id: "19",
    value: "Odisha",
    label: "Odisha",
  },
  {
    id: "20",
    value: "Punjab",
    label: "Punjab",
  },
  {
    id: "21",
    value: "Rajasthan",
    label: "Rajasthan",
  },
  {
    id: "22",
    value: "Sikkim",
    label: "Sikkim",
  },
  {
    id: "23",
    value: "Tamil Nadu",
    label: "Tamil Nadu",
  },
  {
    id: "24",
    value: "Telangana",
    label: "Telangana",
  },
  {
    id: "25",
    value: "Tripura",
    label: "Tripura",
  },
  {
    id: "26",
    value: "Uttar Pradesh",
    label: "Uttar Pradesh",
  },
  {
    id: "27",
    value: "Uttarakhand",
    label: "Uttarakhand",
  },
  {
    id: "28",
    value: "West Bengal",
    label: "West Bengal",
  },
];

export const getStateObject = (state: string) => {
  let stateJson: any;
  STATE.map((stateObj: any) => {
    if (stateObj.label === state) {
      stateJson = stateObj;
    }
  });
  return stateJson;
};

/*export const MEMBER_TYPE = [
  "Head",
  "Wife",
  "Son",
  "Daughter",
  "Mother",
  "Grand Son",
  "Grand Daughter",
  "Daughter-in-law",
];*/

export const MEMBER_TYPE = [
  {
    id: "11111",
    value: "Head",
    label: "Head",
  },
  {
    id: "11112",
    value: "Wife",
    label: "Wife",
  },
  {
    id: "11113",
    value: "Son",
    label: "Son",
  },
  {
    id: "11114",
    value: "Daughter",
    label: "Daughter",
  },
  {
    id: "11115",
    value: "Mother",
    label: "Mother",
  },
  {
    id: "11116",
    value: "Grand Son",
    label: "Grand Son",
  },
  {
    id: "11117",
    value: "Grand Daughter",
    label: "Grand Daughter",
  },
  {
    id: "11118",
    value: "Daughter-in-law",
    label: "Daughter-in-law",
  },
];

export const getMemberTypeObject = (type: string) => {
  let typeJson: any;
  MEMBER_TYPE.map((typeObj: any) => {
    if (typeObj.label === type) {
      typeJson = typeObj;
    }
  });
  return typeJson;
};

export const getMemberType = (member: string) => {
  if (member === "Head") {
    return "H";
  }
  if (member === "Wife") {
    return "W";
  }
  if (member === "Son") {
    return "S";
  }
  if (member === "Daughter") {
    return "D";
  }
  if (member === "Mother") {
    return "M";
  }
  if (member === "Grand Son") {
    return "GS";
  }
  if (member === "Grand Daughter") {
    return "GD";
  }
  if (member === "Daughter-in-law") {
    return "DIL";
  }
  return "";
};

export enum MemberType {
  HEAD = "H",
  WIFE = "W",
  SON = "S",
  DAUGHTER = "D",
  MOTHER = "M",
  GRAND_SON = "GS",
  GRAND_DAUGHTER = "GD",
  DAUGHTER_IN_LAW = "DIL",
}
