
export const DOMAIN = "http://ec2-15-206-74-145.ap-south-1.compute.amazonaws.com:3030";
//export const DOMAIN = 'http://localhost:3030';
export const LOGIN = DOMAIN + "/auth/login";
export const MEMBER = DOMAIN + "/member";
export const SIGN_UP = DOMAIN + "/organization";
export const MESSAGE = DOMAIN + "/message";

export const F_DOMAIN = "https://mycomunity-b7618-default-rtdb.asia-southeast1.firebasedatabase.app";
export const F_API_KEY = "AIzaSyCJZ5-aW8jbrN9QHK_rGZbrCTE9G9dUTQ4";
export const F_LOGIN = "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key="+F_API_KEY;
export const F_MEMBER = F_DOMAIN+"/members.json";
export const F_SIGN_UP = F_DOMAIN+"/organization.json";
export const F_MESSAGE = F_DOMAIN+"/message.json";

export const HEADERS = { 
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImthZGlyZWFsQGdtYWlsLmNvbSIsIm9yZ2FuaXphdGlvbiI6eyJpZCI6IjEiLCJuYW1lIjoiTmFuYWRlc2kgVGhpc2FpIEFBeWlyYXRoaSBBaW5udXR0cnV2YXIgVHJ1c3QiLCJhZGRyZXNzTGluZU9uZSI6IlBhcnZhdGhpIEthbHlhbmEgTWFuZGFwYW0iLCJhZGRyZXNzTGluZVR3byI6IkVhc3QgTWFkYSBTdHJlZXQiLCJjaXR5IjoiQXZpbmFzaGkiLCJzdGF0ZSI6IlROIiwiY291bnRyeSI6IkluZGlhIiwiemlwY29kZSI6IjY0MTAxMzIiLCJjb250YWN0TmFtZSI6IkthZGlydmVsIiwiY29udGFjdEVtYWlsIjoia2FkaXJlYWxAZ21haWwuY29tIiwiY29udGFjdE1vYmlsZSI6Ijk0NDQ5MDQwNTQiLCJjb250YWN0UGhvbmUiOiI5NjU5MjU0NTc1IiwibG9nb0FwcCI6Imh0dHBzOi8vbXljb211bml0eS1kZXYuczMuYW1hem9uYXdzLmNvbS9vcmdhbml6YXRpb24vbG9nby9pbWFnZV8xNjI1NjQwNTIxMDk0XzI2YmUxY2I2LWJlNjYtNDhhNi1iYzU5LTY3YzZmMGE2OWMxOV9vcmdfbG9nby5wbmciLCJsb2dvT3RoZXJzIjpudWxsLCJ0eXBlIjoiQ29tbXVuaXR5IiwiaXAiOiIxMi4xMjMuMTI0LjM0IiwidG9rZW4iOiJWVlJ3YkQiLCJjcmVhdGVkQnkiOjEsInVwZGF0ZWRCeSI6MSwiaXNBY3RpdmUiOnRydWV9LCJpZCI6IjEiLCJyb2xlIjoiQWRtaW4iLCJzdWIiOiIxIiwiaWF0IjoxNjI4NDIyMTQ0fQ.72F4ejSJFJFmPx2FsZP8lJmSuE64gVlgLctOm1N8wAE',
};

export const CONFIG = {
  headers: {
    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImthZGlyZWFsQGdtYWlsLmNvbSIsIm9yZ2FuaXphdGlvbiI6eyJpZCI6IjEiLCJuYW1lIjoiTmFuYWRlc2kgVGhpc2FpIEFBeWlyYXRoaSBBaW5udXR0cnV2YXIgVHJ1c3QiLCJhZGRyZXNzTGluZU9uZSI6IlBhcnZhdGhpIEthbHlhbmEgTWFuZGFwYW0iLCJhZGRyZXNzTGluZVR3byI6IkVhc3QgTWFkYSBTdHJlZXQiLCJjaXR5IjoiQXZpbmFzaGkiLCJzdGF0ZSI6IlROIiwiY291bnRyeSI6IkluZGlhIiwiemlwY29kZSI6IjY0MTAxMzIiLCJjb250YWN0TmFtZSI6IkthZGlydmVsIiwiY29udGFjdEVtYWlsIjoia2FkaXJlYWxAZ21haWwuY29tIiwiY29udGFjdE1vYmlsZSI6Ijk0NDQ5MDQwNTQiLCJjb250YWN0UGhvbmUiOiI5NjU5MjU0NTc1IiwibG9nb0FwcCI6Imh0dHBzOi8vbXljb211bml0eS1kZXYuczMuYW1hem9uYXdzLmNvbS9vcmdhbml6YXRpb24vbG9nby9pbWFnZV8xNjI1NjQwNTIxMDk0XzI2YmUxY2I2LWJlNjYtNDhhNi1iYzU5LTY3YzZmMGE2OWMxOV9vcmdfbG9nby5wbmciLCJsb2dvT3RoZXJzIjpudWxsLCJ0eXBlIjoiQ29tbXVuaXR5IiwiaXAiOiIxMi4xMjMuMTI0LjM0IiwidG9rZW4iOiJWVlJ3YkQiLCJjcmVhdGVkQnkiOjEsInVwZGF0ZWRCeSI6MSwiaXNBY3RpdmUiOnRydWV9LCJpZCI6IjEiLCJyb2xlIjoiQWRtaW4iLCJzdWIiOiIxIiwiaWF0IjoxNjI4NDIyMTQ0fQ.72F4ejSJFJFmPx2FsZP8lJmSuE64gVlgLctOm1N8wAE",
  }
}
