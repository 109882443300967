import Table, { AvatarCell, SelectColumnFilter, StatusPill } from "./Table";
import { useMemo } from "react";

const MemberList = (props: any) => {
  const columns: any = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: AvatarCell,
        imgAccessor: "profileUrl",
        emailAccessor: "email",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
      },
      {
        Header: "Address",
        accessor: "addressLineOne",
      },
      {
        Header: "City",
        accessor: "city",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "State",
        accessor: "state",
        Cell: StatusPill,
      },
    ],
    []
  );

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
        <div className="mt-6">
          <Table columns={columns} data={props.members} />
        </div>
      </main>
    </div>
  );
};
export default MemberList;
