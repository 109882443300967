import axios from "axios";
import { useState } from "react";
import Select from "react-select";
import { F_MEMBER } from "../../constants/ApiPath";
import {
  COUNTRY,
  getMemberType,
  MEMBER_TYPE,
  ORG_TYPE,
  STATE,
} from "../../constants/Constants";
import useInput from "../../hooks/UserInput";
import Button from "../UI/Button";
import ErrorText from "../UI/ErrorText";
import Input from "../UI/Input";
import InputSelect from "../UI/InputSelect";
import LoadingSpinner from "../UI/LoadingSpinner";
import SelectLabel from "../UI/SelectLabel";
import classes from "./AddMember.module.css";

const AddMember = () => {
  const initialState = { error: "", status: 0, message: "" };
  const [type, setType] = useState(MEMBER_TYPE[0]);
  const [country, setCountry] = useState(COUNTRY[0]);
  const [state, setState] = useState(STATE[0]);
  const [resposeError, setResposneError] = useState(initialState);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value: any) => value.trim() !== "" && value.trim().length >= 4);

  const {
    value: refNoValue,
    isValid: refNoIsValid,
    hasError: refNoHasError,
    valueChangeHandler: refNoChangeHandler,
    inputBlurHandler: refNoBlurHandler,
    reset: resetRefNo,
  } = useInput((value: any) => value.trim() !== "" && value.trim().length >= 4);

  const {
    value: mobileValue,
    isValid: mobileIsValid,
    hasError: mobileHasError,
    valueChangeHandler: mobileChangeHandler,
    inputBlurHandler: mobileBlurHandler,
    reset: resetMobile,
  } = useInput(
    (value: any) => value.trim() !== "" && value.trim().length === 10
  );

  const {
    value: addressOneValue,
    isValid: addressOneIsValid,
    hasError: addressOneHasError,
    valueChangeHandler: addressOneChangeHandler,
    inputBlurHandler: addressOneBlurHandler,
    reset: resetAddressOne,
  } = useInput(
    (value: any) => value.trim() !== "" && value.trim().length >= 10
  );

  const {
    value: addressTwoValue,
    isValid: addressTwoIsValid,
    hasError: addressTwoHasError,
    valueChangeHandler: addressTwoChangeHandler,
    inputBlurHandler: addressTwoBlurHandler,
    reset: resetAddressTwo,
  } = useInput((value: any) => value.trim() === "" || value.trim().length > 0);

  const {
    value: cityValue,
    isValid: cityIsValid,
    hasError: cityHasError,
    valueChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
    reset: resetCity,
  } = useInput((value: any) => value.trim() !== "" && value.trim().length >= 4);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput((value: any) => value.trim() !== "" && value.includes("@"));

  const {
    value: phoneValue,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetPhone,
  } = useInput((value: any) => value.trim() === "" || value.trim().length >= 6);

  const {
    value: zipcodeValue,
    isValid: zipcodeIsValid,
    hasError: zipcodeHasError,
    valueChangeHandler: zipcodeChangeHandler,
    inputBlurHandler: zipcodeBlurHandler,
    reset: resetZipcode,
  } = useInput((value: any) => value.trim() !== "" && value.trim().length >= 6);

  let isFormValid = false;
  if (
    nameIsValid &&
    mobileIsValid &&
    addressOneIsValid &&
    addressTwoIsValid &&
    cityIsValid &&
    emailIsValid &&
    zipcodeIsValid &&
    phoneIsValid &&
    refNoIsValid
  ) {
    isFormValid = true;
  }

  const onSubmitHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isFormValid) {
      return;
    }
    const requestData = {
      organizationId: "1",
      name: nameValue,
      addressLineOne: addressOneValue,
      addressLineTwo: addressTwoValue,
      city: cityValue,
      state: state,
      country: country,
      zipcode: zipcodeValue,
      email: emailValue,
      mobile: mobileValue,
      phone: phoneValue,
      type: getMemberType(type.label),
      referenceNo: refNoValue,
    };
    console.log(requestData);
    setIsLoading(true);
    axios
      .post(F_MEMBER, requestData)
      .then((response) => {
        setIsLoading(false);
        resetName();
        resetRefNo();
        resetMobile();
        resetAddressOne();
        resetCity();
        resetEmail();
        resetZipcode();
        resetPhone();
        resetAddressTwo();
        setType(MEMBER_TYPE[0]);
        setCountry(COUNTRY[0]);
        setState(STATE[0]);
      })
      .catch((error) => {
        setIsLoading(false);
        setResposneError(error.response.data);
        setIsError(true);
      });
  };

  const orgNameClasses = nameHasError ? "form-control invalid" : "form-control";

  const mobileClasses = mobileHasError
    ? "form-control invalid"
    : "form-control";
  const addressOneClasses = addressOneHasError
    ? "form-control invalid"
    : "form-control";

  const cityClasses = cityHasError ? "form-control invalid" : "form-control";
  const emailClasses = emailHasError ? "form-control invalid" : "form-control";
  const phoneClasses = phoneHasError ? "form-control invalid" : "form-control";
  const zipcodeClasses = zipcodeHasError
    ? "form-control invalid"
    : "form-control";

  const typeChangeHandler = (selectedType: any) => {
    setType(selectedType);
  };

  const countryChangeHandler = (selectedCountry: any) => {
    setCountry(selectedCountry);
  };

  const stateChangeHandler = (selectedState: any) => {
    setState(selectedState);
  };
  return (
    <div className={classes.login}>
      {isLoading && <LoadingSpinner />}
      <div className={classes.header_div}>
        <h1 className={classes.header}>Add Member</h1>
      </div>
      <div>
        <form className={classes.form} onSubmit={onSubmitHandler}>
          <div className={classes.leftbox}>
            <Input
              classes={orgNameClasses}
              label="Name*"
              input={{
                value: nameValue,
                id: "name",
                type: "text",
                placeholder: "Name of the member",
              }}
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
              error={nameHasError ? "Name must be minimum 4 charactors." : ""}
            />
            <Input
              classes={orgNameClasses}
              label="Reference No*"
              input={{
                value: refNoValue,
                id: "refNo",
                type: "text",
                placeholder: "Reference Number",
              }}
              onChange={refNoChangeHandler}
              onBlur={refNoBlurHandler}
              error={
                refNoHasError
                  ? "Reference number must be minimum 4 charactors."
                  : ""
              }
            />
            <Input
              classes={mobileClasses}
              label="Mobile*"
              input={{
                value: mobileValue,
                id: "mobile",
                type: "number",
                placeholder: "MobileNumber",
              }}
              onChange={mobileChangeHandler}
              onBlur={mobileBlurHandler}
              error={mobileHasError ? "Mobile number must have 10 digits." : ""}
            />
            <Input
              classes={addressOneClasses}
              label="Address Line One*"
              input={{
                value: addressOneValue,
                id: "addressOne",
                type: "text",
                placeholder: "Address Line One",
              }}
              onChange={addressOneChangeHandler}
              onBlur={addressOneBlurHandler}
              error={
                addressOneHasError
                  ? "Address must be minimum 10 charactors."
                  : ""
              }
            />
            <SelectLabel label="Country*" />
            <Select
              id="country"
              label="Country*"
              defaultValue={country}
              options={COUNTRY}
              onChange={countryChangeHandler}
            />
            <Input
              classes={cityClasses}
              label="City*"
              input={{
                value: cityValue,
                id: "city",
                type: "text",
                placeholder: "City",
              }}
              onChange={cityChangeHandler}
              onBlur={cityBlurHandler}
              error={
                cityHasError ? "City name must be minimum 4 charactors." : ""
              }
            />
          </div>

          <div className={classes.rightbox}>
            <SelectLabel label="Type*" />
            <Select
              placeholder={"Type"}
              options={MEMBER_TYPE}
              defaultValue={type}
              onChange={typeChangeHandler}
            />
            <Input
              classes={emailClasses}
              label="Email*"
              input={{
                value: emailValue,
                id: "email",
                type: "email",
                placeholder: "example@company.com",
              }}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              error={emailHasError ? "Provide a valid email address." : ""}
            />
            <Input
              classes={phoneClasses}
              label="Phone"
              input={{
                value: phoneValue,
                id: "phoneNumber",
                type: "number",
                placeholder: "Phone Number",
              }}
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
              error={phoneHasError ? "Phone number must have 6 digits." : ""}
            />
            <Input
              label="Address Line Two"
              input={{
                value: addressTwoValue,
                id: "addressTwo",
                type: "text",
                placeholder: "Address Line Two",
              }}
              onChange={addressTwoChangeHandler}
              onBlur={addressTwoBlurHandler}
              error={addressTwoHasError ? "" : ""}
            />
            <SelectLabel label="State*" />
            <Select
              placeholder={"State"}
              options={STATE}
              defaultValue={state}
              onChange={stateChangeHandler}
            />
            <Input
              classes={zipcodeClasses}
              label="Zipcode*"
              input={{
                value: zipcodeValue,
                id: "zipcode",
                type: "text",
                placeholder: "Zipcode",
              }}
              onChange={zipcodeChangeHandler}
              onBlur={zipcodeBlurHandler}
              error={
                zipcodeHasError ? "Zipcode must be minimum 6 charactors." : ""
              }
            />
          </div>
          <div className={classes.button_div}>
            <Button>Add Member</Button>
            {isError && resposeError.error !== "" && (
              <ErrorText error={resposeError.error} />
            )}
            {isError && resposeError.message !== "" && (
              <ErrorText error={resposeError.message} />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddMember;
