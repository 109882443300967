import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../constants/Routes";
import classes from "./MainNavigation.module.css";

const MainNavigation = () => {
  const history = useHistory();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  const signupHandler = () => {
    history.push(Routes.SIGN_UP);
  };

  return (
    <header className={classes.header}>
      <Link to={Routes.INDEX}>
        <div className={classes.logo}>MyComunity</div>
      </Link>
      <nav>
        <ul>
          {!isLoggedIn && (
            <li>
              <Link to={Routes.AUTH}>Login</Link>
            </li>
          )}
          {!isLoggedIn && (
            <li>
              <button onClick={signupHandler}>Get Started</button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
