import { createSlice } from "@reduxjs/toolkit";
import { Constants } from "../constants/Constants";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn:
      localStorage.getItem(Constants.AUTH_TOKEN)?.trim().length !== 0 &&
      localStorage.getItem(Constants.LOGGED_IN) === Constants.LOGIN,
    authToken:
      localStorage.getItem(Constants.AUTH_TOKEN)?.trim().length !== 0
        ? localStorage.getItem(Constants.AUTH_TOKEN)?.trim()
        : null,
  },
  reducers: {
    logout(state) {
      localStorage.removeItem(Constants.AUTH_TOKEN);
      localStorage.setItem(Constants.LOGGED_IN, Constants.LOGOUT);
      state.isLoggedIn = false;
    },
    login(state: any, action: any) {
      console.log(action.payload);
        localStorage.setItem(Constants.AUTH_TOKEN, action.payload);
      localStorage.setItem(Constants.LOGGED_IN, Constants.LOGIN);
      state.authToken = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
