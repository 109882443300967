import classes from "./ErrorText.module.css";
const ErrorText = (props: any) => {
  const errorClasses = props.center ? "error_center" : "";
  return (
    <div className={errorClasses}>
      <p className={classes.error_text}> {props.error} </p>
    </div>
  );
};
export default ErrorText;
