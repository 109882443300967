import React, { Fragment, Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import { Routes } from "./constants/Routes";
import Login from "./pages/AuthPage";
import NotFound from "./pages/NotFoundPage";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/DashboardPage";

const SignUp = React.lazy(() => import("./pages/SignupPage"));

function App() {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  console.log("isLoggedIn:"+isLoggedIn);
  
return (
    <Fragment>
      <Suspense fallback={<p>Loading.....</p>}>
        <Switch>
          <Route path={Routes.INDEX} exact>
            {isLoggedIn && <Redirect to={Routes.DASHBOARD} />}
            {!isLoggedIn && <HomePage />}
          </Route>
          <Route path={Routes.DASHBOARD} exact>
            {isLoggedIn && <DashboardPage />}
            {!isLoggedIn && <Redirect to={Routes.AUTH} />}
          </Route>
          <Route path={Routes.AUTH} exact>
            {isLoggedIn && <Redirect to={Routes.DASHBOARD} />}
            {!isLoggedIn && <Login />}
          </Route>
          <Route path={Routes.SIGN_UP} exact>
            {isLoggedIn && <Redirect to={Routes.DASHBOARD} />}
            {!isLoggedIn && <SignUp />}
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Fragment>
  );
}

export default App;
