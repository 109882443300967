import React from "react";

import classes from "./Input.module.css";

const Input = React.forwardRef((props: any, ref: any) => {
  return (
    <div className={classes.input}>
      <div className={classes.label_div}>
        <label htmlFor={props.input.id}>{props.label}</label>
      </div>
      <input ref={ref} {...props.input} onChange={props.onChange} onBlur={props.onBlur} />
      <p className={classes.error_text}>{props.error}</p>
    </div>
  );
});

export default Input;
