import { Fragment, useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { F_MEMBER } from "../../constants/ApiPath";
import LoadingSpinner from "../UI/LoadingSpinner";
import MemberList from "./MemberList";

const Members = () => {
  const [members, setMembers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const match = useRouteMatch();
  console.log(match);

  const fetchMemberHandler = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const response = await fetch(
        F_MEMBER /*,
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImthZGlyZWFsQGdtYWlsLmNvbSIsIm9yZ2FuaXphdGlvbiI6eyJpZCI6IjEiLCJuYW1lIjoiTmFuYWRlc2kgVGhpc2FpIEFBeWlyYXRoaSBBaW5udXR0cnV2YXIgVHJ1c3QiLCJhZGRyZXNzTGluZU9uZSI6IlBhcnZhdGhpIEthbHlhbmEgTWFuZGFwYW0iLCJhZGRyZXNzTGluZVR3byI6IkVhc3QgTWFkYSBTdHJlZXQiLCJjaXR5IjoiQXZpbmFzaGkiLCJzdGF0ZSI6IlROIiwiY291bnRyeSI6IkluZGlhIiwiemlwY29kZSI6IjY0MTAxMzIiLCJjb250YWN0TmFtZSI6IkthZGlydmVsIiwiY29udGFjdEVtYWlsIjoia2FkaXJlYWxAZ21haWwuY29tIiwiY29udGFjdE1vYmlsZSI6Ijk0NDQ5MDQwNTQiLCJjb250YWN0UGhvbmUiOiI5NjU5MjU0NTc1IiwibG9nb0FwcCI6Imh0dHBzOi8vbXljb211bml0eS1kZXYuczMuYW1hem9uYXdzLmNvbS9vcmdhbml6YXRpb24vbG9nby9pbWFnZV8xNjI1NjQwNTIxMDk0XzI2YmUxY2I2LWJlNjYtNDhhNi1iYzU5LTY3YzZmMGE2OWMxOV9vcmdfbG9nby5wbmciLCJsb2dvT3RoZXJzIjpudWxsLCJ0eXBlIjoiQ29tbXVuaXR5IiwiaXAiOiIxMi4xMjMuMTI0LjM0IiwidG9rZW4iOiJWVlJ3YkQiLCJjcmVhdGVkQnkiOjEsInVwZGF0ZWRCeSI6MSwiaXNBY3RpdmUiOnRydWV9LCJpZCI6IjEiLCJyb2xlIjoiQWRtaW4iLCJzdWIiOiIxIiwiaWF0IjoxNjI4NDIyMTQ0fQ.72F4ejSJFJFmPx2FsZP8lJmSuE64gVlgLctOm1N8wAE",
            "Content-Type": "application/json",
          },
        }*/
      );
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      const data = await response.json();
      const loadedData: any = [];
      for (const key in data) {
        loadedData.push({
          id: data[key].id,
          name: data[key].name,
          addressLineOne: data[key].addressLineOne,
          addressLineTwo: data[key].addressLineTwo,
          city: data[key].city,
          state: data[key].state,
          country: data[key].country,
          zipcode: data[key].zipcode,
          email: data[key].email,
          mobile: data[key].mobile,
          phone: data[key].phone,
          verifiedAt: data[key].verifiedAt,
          referenceNo: data[key].referenceNo,
          profileUrl: data[key].profileUrl,
          type: data[key].type,
          createdBy: data[key].createdBy,
          updatedBy: data[key].updatedBy,
          isActive: data[key].isActive,
        });
      }
      //const data = await response.json();
      console.log(data);
      //setMembers(data);
      setMembers(loadedData);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchMemberHandler();
  }, [fetchMemberHandler]);

  let content = <p>Found no members.</p>;

  if (members.length > 0) {
    content = <MemberList members={members} />;
  }

  if (error) {
    content = <p>{error}</p>;
  }

  if (isLoading) {
    content = <LoadingSpinner />;
  }

  return (
    <Fragment>
      {<section>{content}</section>}
    </Fragment>
  );
};
export default Members;